<template>
  <b-row>
    <b-col sm="12" xl="8">
      <CCard title="Filter Laporan">
        <CForm ref="forms" @submit="submitForm">
          <b-row cols="1" cols-xl="2">
            <b-col>
              <FormDate :rules="{required: true}" v-model="saleDateFilter.date_1" label="Periode Awal" :max="saleDateFilter.date_2" />
            </b-col>
            <b-col>
              <FormDate :rules="{required: true}" v-model="saleDateFilter.date_2" label="Periode Akhir" :min="saleDateFilter.date_1" />
            </b-col>
          </b-row>
          <FormSelectAll @option:deselected="categorySplice" @option:selected="categorySplice" nested multiple v-model="itemReportFilter.categories" endpoint="v1/item_categories/selectkat" label="Kategori"/>
          <FormSelectAll @option:deselected="brandSplice" @option:selected="brandSplice" multiple v-model="itemReportFilter.brands" endpoint="v1/report_filters/brand" :query="{id_category: categorySplit}" label="Merek"/>
          <FormSelectAll @option:deselected="colorSplice" @option:selected="colorSplice" multiple v-model="itemReportFilter.colors" endpoint="v1/report_filters/color" :query="{id_category: categorySplit, id_brand: brandSplit}" label="Warna"/>
          <FormSelectAll multiple v-model="itemReportFilter.sizes" endpoint="v1/report_filters/size" :query="{id_category: categorySplit, id_brand: brandSplit, id_color: colorSplit}" label="Ukuran"/>
          <FormSelectAll multiple v-model="itemReportFilter.payment_types" endpoint="v1/payment_partners/select2" label="Tipe Pembayaran"/>
          <div>
            <CButton :loading="buttonloading" type="submit">Cetak Excel</CButton>
            <CButton @click="resetForm" variant="warning">Reset</CButton>
          </div>
        </CForm>
      </CCard>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import CForm from '@/utils/components/CForm.vue'
import FormDate from '@/utils/components/FormDate.vue'
import CButton from '@/utils/components/CButton.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import map from 'lodash/map'
import join from 'lodash/join'
import FileSaver from 'file-saver'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { CCard, CForm, FormDate, CButton, FormSelectAll },
  data(){
    return {
      buttonloading: false
    }
  },
  computed:{
    ...mapFields('filter',['itemReportFilter','saleDateFilter']),
    formData(){
      const fd = {}
      fd.date_1 = this.saleDateFilter.date_1
      fd.date_2 = this.saleDateFilter.date_2
      if(this.itemReportFilter.categories.length>0) {
        fd.id_category = map(this.itemReportFilter.categories,'value')
        fd.category_name = map(this.itemReportFilter.categories,'label')
      } else {
        fd.id_category = []
        fd.category_name = []
      }
      if(this.itemReportFilter.brands.length>0) {
        fd.id_brand = map(this.itemReportFilter.brands,'value')
        fd.brand_name = map(this.itemReportFilter.brands,'label')
      } else {
        fd.id_brand = []
        fd.brand_name = []
      }
      if(this.itemReportFilter.colors.length>0) {
        fd.id_color = map(this.itemReportFilter.colors,'value')
        fd.color_name = map(this.itemReportFilter.colors,'label')
      } else {
        fd.id_color = []
        fd.color_name = []
      }
      if(this.itemReportFilter.sizes.length>0) {
        fd.id_size = map(this.itemReportFilter.sizes,'value')
        fd.size_name = map(this.itemReportFilter.sizes,'label')
      } else {
        fd.id_size = []
        fd.size_name = []
      }
      if(this.itemReportFilter.payment_types.length>0) {
        fd.id_payment_partner = map(this.itemReportFilter.payment_types,'value')
        fd.payment_partner_name = map(this.itemReportFilter.payment_types,'label')
      } else {
        fd.id_payment_partner = []
        fd.payment_partner_name = []
      }

      return fd
    },
    categorySplit(){
      return join(this.formData.id_category,',')
    },
    brandSplit(){
      return join(this.formData.id_brand,',')
    },
    colorSplit(){
      return join(this.formData.id_color,',')
    }
  },
  methods:{
    async submitForm(){
      this.buttonloading = true
      try {
        const { data } = await this.$http.post(`v1/report_sales/excel`,this.formData,{responseType:'blob'})
        FileSaver.saveAs(data,`Laporan Penjualan.xlsx`)
        this.buttonloading = false
      } catch (error) {
        this.handleError(error)
        this.buttonloading = false
      }
    },
    resetForm(){
      this.saleDateFilter.date_1 = this.$moment().startOf('day')
      this.saleDateFilter.date_2 = this.$moment().endOf('day')
      this.itemReportFilter.categories = []
      this.itemReportFilter.brands = []
      this.itemReportFilter.colors = []
      this.itemReportFilter.sizes = []
      this.itemReportFilter.payment_types = []
    },
    categorySplice(){
      this.itemReportFilter.brands = []
      this.itemReportFilter.colors = []
      this.itemReportFilter.sizes = []
    },
    brandSplice(){
      this.itemReportFilter.colors = []
      this.itemReportFilter.sizes = []
    },
    colorSplice(){
      this.itemReportFilter.sizes = []
    }
  }
}
</script>

<style>

</style>