var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('b-button-group',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.downloadLocale}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v(" Download Penjualan")],1),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.openUpload}},[_c('feather-icon',{attrs:{"icon":"LogInIcon"}}),_vm._v(" Impor Penjualan")],1),_c('b-button',{staticClass:"font-weight-bolder",attrs:{"disabled":_vm.isPushLoading,"variant":"primary"},on:{"click":_vm.pushConfirmation}},[(_vm.isPushLoading)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Loading...")],1):_c('span',[_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}}),_vm._v(" Push To Server")],1)])],1)],1),_c('b-progress',{attrs:{"hidden":"","height":"1rem","value":13,"max":50,"animated":""}}),_c('b-table',{staticClass:"mt-1",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":"","striped":"","hover":"","outlined":""},on:{"row-clicked":function (item){ return _vm.$set(item, '_showDetails', !item._showDetails); }},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [(!row.detailsShowing)?_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}}):_c('feather-icon',{attrs:{"icon":"ChevronDownIcon"}})]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('b-card',{attrs:{"title":("Detail Transaksi " + (item.type_transaction==3?'Penjualan':'Penukaran')),"border-variant":"primary"}},[(item.type_transaction==4)?[_c('h6',{staticClass:"small font-weight-bolder"},[_vm._v("Barang Masuk")]),_c('b-table',{attrs:{"striped":"","small":"","responsive":"","bordered":"","fields":_vm.detailInsFields,"items":item.detail_ins},scopedSlots:_vm._u([{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" Rp."+_vm._s(_vm._f("formatNumber")(item.price))+" ")]}},{key:"cell(price_total)",fn:function(ref){
var item = ref.item;
return [_vm._v(" Rp."+_vm._s(_vm._f("formatNumber")(item.price_total))+" ")]}}],null,true)}),_c('h6',{staticClass:"small font-weight-bolder"},[_vm._v("Barang Keluar")])]:_vm._e(),_c('b-table',{attrs:{"striped":"","small":"","responsive":"","bordered":"","fields":_vm.detailsFields,"items":item.type_transaction==3?item.details:item.detail_outs},scopedSlots:_vm._u([{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" Rp."+_vm._s(_vm._f("formatNumber")(item.price))+" ")]}},{key:"cell(price_total)",fn:function(ref){
var item = ref.item;
return [_vm._v(" Rp."+_vm._s(_vm._f("formatNumber")(item.price_total))+" ")]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"sm":"12","xl":"6","order":"1","order-xl":"0"}},[_c('p',{staticClass:"small"},[_vm._v(" Total Item : "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm._f("formatNumber")(item.total_item)))]),_c('br'),_vm._v(" Dilayani Oleh : "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(item.staff))]),_c('br'),_vm._v(" Catatan : "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(item.note))])])]),_c('b-col',{attrs:{"sm":"12","offset-xl":"3","xl":"3","order":"0","order-xl":"1"}},[(item.type_transaction==3)?[_c('p',{staticClass:"d-flex justify-content-between small"},[_c('span',[_vm._v("Sub Total")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.subtotal)))])]),_c('p',{staticClass:"d-flex justify-content-between small"},[_c('span',[_vm._v("Diskon")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.disc_total)))])]),_c('p',{staticClass:"d-flex justify-content-between small"},[_c('span',[_vm._v("Tot Penjualan")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.grand_total)))])])]:[_c('p',{staticClass:"d-flex justify-content-between small"},[_c('span',[_vm._v("Selisih")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.difference)))])])],_c('p',{staticClass:"d-flex justify-content-between small"},[_c('span',[_vm._v("Bayar")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.payment_amount)))])]),_c('p',{staticClass:"d-flex justify-content-between small"},[_c('span',[_vm._v("Kembali")]),_c('span',{staticClass:"font-weight-bolder"},[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.payment_change)))])])],2)],1)],2)]}},{key:"cell(is_sync)",fn:function(ref){
var item = ref.item;
return [(item.is_sync)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Synced")]):_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Not Synced")])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fulldate")(item.date))+" ")]}},{key:"cell(grand_total)",fn:function(ref){
var item = ref.item;
return [(item.type_transaction==3)?_c('span',[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.grand_total)))]):_c('span',[_vm._v("Rp."+_vm._s(_vm._f("formatNumber")(item.difference)))])]}},{key:"cell(kind)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.transactionKind({type_transaction:item.type_transaction, kind: item.kind}).variant}},[_vm._v(_vm._s(_vm.transactionKind({type_transaction:item.type_transaction, kind: item.kind}).label))])]}},{key:"cell(uniqid)",fn:function(ref){
var item = ref.item;
return [(!_vm.isPushLoading)?_c('b-button-group',{attrs:{"size":"sm"}},[(item.kind!==3 && (item.type_transaction==3&&_vm.cP(68)) || (item.type_transaction==4&&_vm.cP(72)))?_c('b-button',{attrs:{"to":{name:item.type_transaction==3?'sales':'returns',query:{uniqid: item.uniqid}},"variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1):_vm._e(),(item.kind!==3 && (item.type_transaction==3&&_vm.cP(69)) || (item.type_transaction==4&&_vm.cP(73)))?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteItems(item.uniqid,item.type_transaction,item.kind)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),([3,6].includes(item.kind) && !item.is_sync)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.undoDelete(item.uniqid,item.type_transaction,item.oldkind)}}},[_c('feather-icon',{attrs:{"icon":"CornerUpLeftIcon"}})],1):_vm._e()],1):_vm._e()]}},{key:"empty",fn:function(){return [_c('h6',[_vm._v("Tidak ada data.")])]},proxy:true}])}),_c('b-form-file',{ref:"fileInputs",staticClass:"hidden",attrs:{"id":"fileInputs","name":"fileInputs","accept":".json"},on:{"change":_vm.confirmUpload},model:{value:(_vm.jsonFile),callback:function ($$v) {_vm.jsonFile=$$v},expression:"jsonFile"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }