<template>
  <b-tabs align="center" pills>
    <b-tab title="Hari Ini" lazy active>
      <b-card>
        <local-transactions></local-transactions>
      </b-card>
    </b-tab>
    <b-tab title="Sebelumnya" lazy>
      <past-transactions></past-transactions>
    </b-tab>
  </b-tabs>
</template>

<script>
import {BTabs,BTab,BCard} from 'bootstrap-vue'
import LocalTransactions from './LocalTransactions.vue'
import PastTransactions from './PastTransactions.vue'

export default {
  components:{
    BTabs,
    BTab,
    LocalTransactions,
    BCard,
    PastTransactions
  }
}
</script>

<style>

</style>