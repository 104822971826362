<template>
  <div>
    <div class="d-flex justify-content-end mb-1">
      <b-button-group>
        <b-button @click="downloadLocale" variant="outline-primary"><feather-icon icon="DownloadIcon"></feather-icon> Download Penjualan</b-button>
        <b-button @click="openUpload" variant="outline-primary"><feather-icon icon="LogInIcon"></feather-icon> Impor Penjualan</b-button>
        <b-button class="font-weight-bolder" @click="pushConfirmation" :disabled="isPushLoading" variant="primary">
          <span v-if="isPushLoading"><b-spinner small type="grow"></b-spinner> Loading...</span>
          <span v-else><feather-icon icon="UploadCloudIcon"></feather-icon> Push To Server</span>
        </b-button>
      </b-button-group>
    </div>
    <b-progress hidden height="1rem" :value="13" :max="50" animated></b-progress>
    <b-table class="mt-1" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" :fields="fields" :items="items" responsive striped hover outlined>
      <template #cell(show_details)="row">
        <feather-icon v-if="!row.detailsShowing" icon="ChevronRightIcon"></feather-icon>
        <feather-icon v-else icon="ChevronDownIcon"></feather-icon>
      </template>
      <template #row-details="{item}">
        <b-card :title="`Detail Transaksi ${item.type_transaction==3?'Penjualan':'Penukaran'}`" border-variant="primary">
          <template v-if="item.type_transaction==4">
            <h6 class="small font-weight-bolder">Barang Masuk</h6>
            <b-table striped small responsive bordered :fields="detailInsFields" :items="item.detail_ins">
              <template #cell(price)="{item}">
                Rp.{{item.price | formatNumber}}
              </template>
              <template #cell(price_total)="{item}">
                Rp.{{item.price_total | formatNumber}}
              </template>
            </b-table>
            <h6 class="small font-weight-bolder">Barang Keluar</h6>
          </template>
          <b-table striped small responsive bordered :fields="detailsFields" :items="item.type_transaction==3?item.details:item.detail_outs">
            <template #cell(price)="{item}">
              Rp.{{item.price | formatNumber}}
            </template>
            <template #cell(price_total)="{item}">
              Rp.{{item.price_total | formatNumber}}
            </template>
          </b-table>
          <b-row>
            <b-col sm="12" xl="6" order="1" order-xl="0">
              <p class="small">
                Total Item : <span class="font-weight-bolder">{{item.total_item | formatNumber}}</span><br>
                Dilayani Oleh : <span class="font-weight-bolder">{{item.staff}}</span><br>
                Catatan : <span class="font-weight-bolder">{{item.note}}</span>
              </p>
            </b-col>
            <b-col sm="12" offset-xl="3" xl="3" order="0" order-xl="1">
              <template v-if="item.type_transaction==3">
                <p class="d-flex justify-content-between small">
                  <span>Sub Total</span>
                  <span class="font-weight-bolder">Rp.{{item.subtotal | formatNumber}}</span>
                </p>
                <p class="d-flex justify-content-between small">
                  <span>Diskon</span>
                  <span class="font-weight-bolder">Rp.{{item.disc_total | formatNumber}}</span>
                </p>
                <p class="d-flex justify-content-between small">
                  <span>Tot Penjualan</span>
                  <span class="font-weight-bolder">Rp.{{item.grand_total | formatNumber}}</span>
                </p>
              </template>
              <template v-else>
                <p class="d-flex justify-content-between small">
                  <span>Selisih</span>
                  <span class="font-weight-bolder">Rp.{{item.difference | formatNumber}}</span>
                </p>
              </template>
              <p class="d-flex justify-content-between small">
                <span>Bayar</span>
                <span class="font-weight-bolder">Rp.{{item.payment_amount | formatNumber}}</span>
              </p>
              <p class="d-flex justify-content-between small">
                <span>Kembali</span>
                <span class="font-weight-bolder">Rp.{{item.payment_change | formatNumber}}</span>
              </p>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #cell(is_sync)="{item}">
        <b-badge v-if="item.is_sync" variant="success">Synced</b-badge>
        <b-badge v-else variant="danger">Not Synced</b-badge>
      </template>
      <template #cell(date)="{item}">
        {{item.date | fulldate}}
      </template>
      <template #cell(grand_total)="{item}">
        <span v-if="item.type_transaction==3">Rp.{{item.grand_total | formatNumber}}</span>
        <span v-else>Rp.{{item.difference | formatNumber}}</span>
      </template>
      <template #cell(kind)="{item}">
        <b-badge :variant="transactionKind({type_transaction:item.type_transaction, kind: item.kind}).variant">{{transactionKind({type_transaction:item.type_transaction, kind: item.kind}).label}}</b-badge>
      </template>
      <template #cell(uniqid)="{item}">
        <b-button-group v-if="!isPushLoading" size="sm">
          <b-button v-if="item.kind!==3 && (item.type_transaction==3&&cP(68)) || (item.type_transaction==4&&cP(72))" :to="{name:item.type_transaction==3?'sales':'returns',query:{uniqid: item.uniqid}}" variant="primary"><feather-icon icon="EditIcon"></feather-icon></b-button>
          <b-button v-if="item.kind!==3 && (item.type_transaction==3&&cP(69)) || (item.type_transaction==4&&cP(73))" @click="deleteItems(item.uniqid,item.type_transaction,item.kind)" variant="danger"><feather-icon icon="TrashIcon"></feather-icon></b-button>
          <b-button v-if="[3,6].includes(item.kind) && !item.is_sync" variant="primary" @click="undoDelete(item.uniqid,item.type_transaction,item.oldkind)"><feather-icon icon="CornerUpLeftIcon"></feather-icon></b-button>
        </b-button-group>
      </template>
      <template #empty>
        <h6>Tidak ada data.</h6>
      </template>
    </b-table>
    <b-form-file ref="fileInputs" id="fileInputs" name="fileInputs" class="hidden" v-model="jsonFile" @change="confirmUpload" accept=".json"></b-form-file>
  </div>
</template>

<script>
import {BTable,BButtonGroup,BButton,BSpinner,BBadge,BCard,BFormFile,BProgress} from 'bootstrap-vue'
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'
// import {findDevice} from '../../../utils/js/printer-init'

export default {
  components:{
    BTable,
    BButtonGroup,
    BButton,
    BSpinner,
    BBadge,
    BCard,
    BFormFile,
    BProgress
  },
  data(){
    const typeTransactions = [
      {type_transaction:3,kind:1,label:"Penjualan Baru",variant:"primary"},
      {type_transaction:3,kind:2,label:"Edit Penjualan",variant:"primary"},
      {type_transaction:3,kind:3,label:"Hapus Penjualan",variant:"danger"},
      {type_transaction:4,kind:4,label:"Penukaran Baru",variant:"info"},
      {type_transaction:4,kind:5,label:"Edit Penukaran",variant:"info"},
      {type_transaction:4,kind:6,label:"Hapus Penukaran",variant:"danger"},
    ]
    return {
      fields:[
        {key:"show_details",label:""},
        {key:"code_ref",label:"No. Transaksi"},
        {key:"date",label:"Tanggal"},
        {key:"payment_partner",label:"Pembayaran"},
        {key:"grand_total",label:"Total Harga / Selisih"},
        {key:"is_sync",label:"Status"},
        {key:"kind",label:"Jenis"},
        {key:"uniqid",label:"",tdClass:'d-flex justify-content-end'},
      ],
      detailInsFields:[
        {key:"code_ref",label:"No. Penjualan"},
        {key:"code",label:"Kode Barang"},
        {key:"brand_name",label:"Merek"},
        {key:"code_article",label:"Artikel"},
        {key:"color_name",label:"Warna"},
        {key:"size_name",label:"Nomor"},
        {key:"qty",label:"Qty",tdClass:'text-right'},
        {key:"price",label:"Harga",tdClass:'text-right'},
        {key:"price_total",label:"Total",tdClass:'text-right'},
      ],
      detailsFields:[
        {key:"code",label:"Kode Barang"},
        {key:"brand_name",label:"Merek"},
        {key:"code_article",label:"Artikel"},
        {key:"color_name",label:"Warna"},
        {key:"size_name",label:"Nomor"},
        {key:"qty",label:"Qty",tdClass:'text-right'},
        {key:"price",label:"Harga",tdClass:'text-right'},
        {key:"price_total",label:"Total",tdClass:'text-right'},
      ],
      isPushLoading: false,
      typeTransactions,
      jsonFile: null,
    }
  },
  computed:{
    items(){
      return this.$store.state.pos.local_transactions
    }
  },
  methods:{
    pushConfirmation(){
      this.confirmation(`pushToServer`,{title:`Upload Transaksi ke Server ?`})
    },
    async pushToServer(){
      this.isPushLoading = true
      const records = this.items
      let exe = []
      for (let i = 0; i < records.length; i++) {
        const params = records[i];
        if(!params.is_sync) {
          /*
          try {
            await this.$store.dispatch('pos/postTransaction',params)
          } catch (error) {
            this.handleError(error)
            continue
          }
          */
          exe.push(this.$store.dispatch('pos/postTransaction',params))
        }
      }
      Promise.all(exe).then(()=>{
        this.$store.commit('pos/ACTION_SAVE_STATE_TO_LOCALSTORAGE')
        // this.$store.dispatch('pos/getLocalSaleFromStorage')
        this.notify(`Upload ke Server Selesai.`,`OK`)
        this.isPushLoading = false
      })
      .catch((error)=>{
        this.$store.commit('pos/ACTION_SAVE_STATE_TO_LOCALSTORAGE')
        // this.$store.dispatch('pos/getLocalSaleFromStorage')
        this.notify(`Proses Selesai.`,`OK`)
        this.isPushLoading = false
        this.handleError(error)
      })
    },
    transactionKind(data){
      const trx = this.typeTransactions
      const finds = find(trx,{type_transaction:data.type_transaction, kind:data.kind})
      if(finds) {
        return finds
      } else {
        return {}
      }
    },
    deleteItems(uniqid,type_transaction,kind){
      this.confirmation(`yesDelete`,{
        title: `Konfirmasi`,
        text: `Apakah Anda Ingin Menghapus Penjualan Ini ?`,
        parameter: {uniqid,type_transaction,kind}
      })
    },
    async yesDelete(params){
      await this.$store.dispatch('pos/modifyLocalTransaction',{uniqid:params.uniqid,kind: params.type_transaction==3 ? 3 : 6,is_sync:false,oldkind:params.kind})
      this.$store.dispatch('pos/getLocalSaleFromStorage')
    },
    downloadLocale(){
      const data = localStorage.getItem("localSaleTransactions")||"[]"
      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "order-data" + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    openUpload(){
      document.getElementById('fileInputs').click()
    },
    confirmUpload(){
      this.confirmation(`uploadLocalJson`,{text:`Perhatian! Jika terdapat data sebelumnya pada penyimpanan lokal, maka data lama akan di <b>Replace</b> dengan data yang baru.`,title:`Upload File Transaksi ?`})
    },
    uploadLocalJson(){
      const files = document.getElementById('fileInputs').files
      if (files.length <= 0) {
        return false;
      }

      const fr = new FileReader()
      fr.readAsText(files.item(0))
      fr.onload = (e) => {
        const json = JSON.parse(e.target.result)
        const existing = localStorage.getItem("localSaleTransactions")
        if(existing) {
          const exs = JSON.parse(existing)
          const inserted = uniqBy([...exs,...json],'uniqid')
          localStorage.setItem("localSaleTransactions", JSON.stringify(inserted))
        } else {
          localStorage.setItem("localSaleTransactions", JSON.stringify(json))
        }
        // localStorage.setItem("localSaleTransactions", JSON.stringify(json))
        this.incrementKey()
      }
    },
    templatingNote(){

    },
    async undoDelete(uniqid,type_transaction,oldkind){
      await this.$store.dispatch('pos/modifyLocalTransaction',{uniqid:uniqid,kind: oldkind ? oldkind : type_transaction==3 ? 1 : 4})
      this.$store.dispatch('pos/getLocalSaleFromStorage')
    }
  },
  created(){
    this.$store.dispatch('pos/getLocalSaleFromStorage')
    // findDevice()
  }
}
</script>

<style>

</style>