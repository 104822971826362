<template>
  <div>
    <c-card title="Filter" class="mb-1">
      <c-form ref="filterForm" @submit="filterSubmit">
        <div class="d-flex justify-content-between">
          <form-date :max="transactionFilter.date_2" class="w-100 mr-1" v-model="transactionFilter.date_1" label="Periode Awal"></form-date>
          <form-date :min="transactionFilter.date_1" class="w-100" v-model="transactionFilter.date_2" label="Periode Akhir"></form-date>
        </div>
        <form-select-static v-model="transactionFilter.type_transactions" label="Tipe Transaksi" :options="typeTransactions"></form-select-static>
        <b-button class="mr-1" type="submit" variant="primary">Sortir</b-button>
        <b-button @click="resetFilter" variant="outline-warning">Reset</b-button>
      </c-form>
    </c-card>
    <c-card>
      <div class="d-flex justify-content-between mb-1">
        <div>
          <small class="padding">Tampilkan </small>
          <label>
            <BFormSelect
              @input="getData()"
              v-model="pageLength"
              :options="lengthArray"
            />
          </label>
          <small class="padding"> Entri</small>
        </div>
        <div class="w-25">
          <BInputGroup>
            <BFormInput @input="getData()" v-model="search" type="search" placeholder="Cari..."></BFormInput>
            <BInputGroupAppend is-text>
              <feather-icon icon="SearchIcon"></feather-icon>
            </BInputGroupAppend>
          </BInputGroup>
        </div>
      </div>
      <BTable @row-clicked="openRow" responsive striped hover outlined :fields="fields" :items="items">
        <template #cell(date)="{item}">
          {{item.date | fulldate}}
        </template>
        <template #cell(grand_total)="{item}">
          Rp.{{item.grand_total | formatNumber}}
        </template>
        <template #cell(show_details)="row">
          <feather-icon v-if="!row.detailsShowing" icon="ChevronRightIcon"></feather-icon>
          <feather-icon v-else icon="ChevronDownIcon"></feather-icon>
        </template>
        <template #row-details="{item}">
          <BCard v-if="item.uniqid" :title="`Detail Transaksi ${item.type_transaction==3?'Penjualan':'Penukaran'}`" border-variant="primary">
            <template v-if="item.type_transaction==4">
              <h6 class="small font-weight-bolder">Barang Masuk</h6>
              <BTable striped small responsive bordered :fields="detailInsFields" :items="item.detail_ins">
                <template #cell(price)="{item}">
                  Rp.{{item.price | formatNumber}}
                </template>
                <template #cell(price_total)="{item}">
                  Rp.{{item.price_total | formatNumber}}
                </template>
              </BTable>
              <h6 class="small font-weight-bolder">Barang Keluar</h6>
            </template>
            <BTable striped small responsive bordered :fields="detailsFields" :items="item.type_transaction==3?item.details:item.detail_outs">
              <template #cell(price)="{item}">
                Rp.{{item.price | formatNumber}}
              </template>
              <template #cell(price_total)="{item}">
                Rp.{{item.price_total | formatNumber}}
              </template>
            </BTable>
            <BRow>
              <BCol sm="12" xl="6" order="1" order-xl="0">
                <p class="small">
                  Total Item : <span class="font-weight-bolder">{{item.total_item | formatNumber}}</span><br>
                  Dilayani Oleh : <span class="font-weight-bolder">{{item.staff}}</span><br>
                  Catatan : <span class="font-weight-bolder">{{item.note}}</span>
                </p>
              </BCol>
              <BCol sm="12" offset-xl="3" xl="3" order="0" order-xl="1">
                <template v-if="item.type_transaction==3">
                  <p class="d-flex justify-content-between small">
                    <span>Sub Total</span>
                    <span class="font-weight-bolder">Rp.{{item.subtotal | formatNumber}}</span>
                  </p>
                  <p class="d-flex justify-content-between small">
                    <span>Diskon</span>
                    <span class="font-weight-bolder">Rp.{{item.disc_total | formatNumber}}</span>
                  </p>
                  <p class="d-flex justify-content-between small">
                    <span>Tot Penjualan</span>
                    <span class="font-weight-bolder">Rp.{{item.grand_total | formatNumber}}</span>
                  </p>
                </template>
                <template v-else>
                  <p class="d-flex justify-content-between small">
                    <span>Selisih</span>
                    <span class="font-weight-bolder">Rp.{{item.difference | formatNumber}}</span>
                  </p>
                </template>
                <p class="d-flex justify-content-between small">
                  <span>Bayar</span>
                  <span class="font-weight-bolder">Rp.{{item.payment_amount | formatNumber}}</span>
                </p>
                <p class="d-flex justify-content-between small">
                  <span>Kembali</span>
                  <span class="font-weight-bolder">Rp.{{item.payment_change | formatNumber}}</span>
                </p>
              </BCol>
            </BRow>
          </BCard>
        </template>
      </BTable>
      <div class="d-flex justify-content-between">
        <span class="padding small">Menampilkan {{items.length}} dari {{total}} data.</span>
        <BPagination v-model="page" :per-page="pageLength" :total-rows="total" ></BPagination>
      </div>
    </c-card>
  </div>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import CForm from '@/utils/components/CForm.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormSelectStatic from '@/utils/components/FormSelectStatic.vue'
import {BTable, BPagination, BFormSelect, BInputGroup, BFormInput, BInputGroupAppend, BCard, BButton} from 'bootstrap-vue'
import debounce from 'lodash/debounce'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { BButton, CCard, BTable, BPagination, BFormSelect, BInputGroup, BFormInput, BInputGroupAppend, BCard, CForm, FormDate, FormSelectStatic },
  data(){
    return {
      search:"",
      page:1,
      pageLength: 10,
      sortKey:'date',
      sortDesc:true,
      items:[],
      lengthArray:[
        10,
        25,
        50,
        100
      ],
      total:0,
      total_pages:1,
      detailsFields:[
        {key:"code",label:"Kode Barang"},
        {key:"brand_name",label:"Merek"},
        {key:"code_article",label:"Artikel"},
        {key:"color_name",label:"Warna"},
        {key:"size_name",label:"Nomor"},
        {key:"qty",label:"Qty",tdClass:'text-right'},
        {key:"price",label:"Harga",tdClass:'text-right'},
        {key:"price_total",label:"Total",tdClass:'text-right'},
      ],
      detailInsFields:[
        {key:"code_ref",label:"No. Penjualan"},
        {key:"code",label:"Kode Barang"},
        {key:"brand_name",label:"Merek"},
        {key:"code_article",label:"Artikel"},
        {key:"color_name",label:"Warna"},
        {key:"size_name",label:"Nomor"},
        {key:"qty",label:"Qty",tdClass:'text-right'},
        {key:"price",label:"Harga",tdClass:'text-right'},
        {key:"price_total",label:"Total",tdClass:'text-right'},
      ],
      typeTransactions:[
        {value:3,label:'Penjualan'},
        {value:4,label:'Penukaran'},
      ]
    }
  },
  computed:{
    fields(){
      return [
        {key:"show_details",label:""},
        {key:"code_ref",label:"No. Transaksi"},
        {key:"date",label:"Tanggal"},
        {key:"payment_partner_name",label:"Pembayaran"},
        {key:"grand_total",label:"Total Harga / Selisih"},
        // {key:"type_transaction",label:"Jenis"},
        // {key:"id",label:""},
      ]
    },
    ...mapFields('filter',['transactionFilter']),
    filterParams(){
      const dt = JSON.parse(JSON.stringify(this.transactionFilter))
      let fd = {}
      if(dt.date_1) fd.date_1 = dt.date_1
      if(dt.date_2) fd.date_2 = dt.date_2
      if(dt.type_transactions) fd.type_transactions = dt.type_transactions.value
      return fd
    }
  },
  watch:{
    page(){
      const vm = this
      vm.getData()
    },
    pageLength(){
      const vm = this
      vm.page = 1
      vm.getData()
    }
  },
  methods:{
    async getData(){
      this.onLoad(this)
    },
    onLoad: debounce((vm) => {
      vm.isloading = true
      const params = {
        search: vm.search,
        page: vm.page,
        per_page: vm.pageLength,
        sort: vm.sortKey,
        order: vm.sortDesc ? 'desc':'asc',
        ...vm.filterParams
      }
      vm.$http.get(`v1/pos/index`,{params:params}).then(e => {
        const {data,total,total_pages} = e.data
        vm.items = data
        vm.total = total
        vm.total_pages = total_pages
        vm.isloading = false
      }).catch(()=>{
        vm.isloading = false
      })
    },200),
    async getDetails({id,type_transactions},index){
      const {data} = await this.$http.get(`v1/pos/show`,{params:{id,type:type_transactions}})
      const parse = {...data,_showDetails:true}
      this.$set(this.items,index,parse)
    },
    openRow(item,index){
      this.$set(item, '_showDetails', !item._showDetails)
      this.getDetails(item,index)
    },
    filterSubmit(){
      this.getData()
    },
    resetFilter(){
      const vm = this
      vm.$store.commit('filter/RESET_TRANSACTIONS')
      vm.$nextTick(()=>{
        vm.getData()
      })
    },
  },
  created(){
    this.getData()
  }
}
</script>

<style>

</style>